import { useEffect, useState } from 'react';
import { getShopList, getUserData, getUserRole, postGetOTP, postValidateOTP } from 'services/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LS_AUTH, LS_ROLES, LS_USER } from '../constants';
import { LS_PHONE_NUMBER, LS_SELECTED_SHOP_NAME, LS_SHOP, LS_USERNAME } from 'constants/index';
import { setInitialShopState, setPermission } from 'store/reducers/shop';
import { useCommonHooks } from './common';
import { useLang } from './common/lang';
export const useLoginHooks = () => {
    const { $t } = useLang();
    const [otp, setOtp] = useState();
    const [phone, setPhone] = useState();
    const [token, setToken] = useState();
    const [code, setCode] = useState();
    const [errOtp, setErrOtp] = useState();
    const [isRememberLogin, setIsRememberLogin] = useState(false);
    const [isLSCleared, setIsLSCleared] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { initiateShopInfo } = useCommonHooks();
    const handleSendOtpToUser = async (phoneInput, code, method) => {
        const payload = {
            phone_number: `${phoneInput}`,
            message_type: method,
            country_code: code
        };
        const response = await postGetOTP(payload);
        if (response.code === 200) {
            const { token, user_id } = response.data;
            setPhone(phoneInput);
            setCode(code);
            setToken(token);
            localStorage.setItem(LS_USER, user_id);
            return null;
        } else {
            return response.err_msg;
        }
    };
    const handleValidateOtp = async (finalOtp) => {
        const payload = {
            otp_code: parseInt(finalOtp),
            phone_number: `${phone}`,
            token,
            remember_me: isRememberLogin,
            country_code: code
        };
        const response = await postValidateOTP(payload);
        if (response?.code === 200) {
            localStorage.setItem(LS_AUTH, response.data.auth_token);
            fetchUserShopList();
            fetchUserData();
        } else {
            if (response.err_msg.toLowerCase() === 'error unauthorized') {
                setErrOtp($t('auth.OpErrorInvalidOtp'));
            } else if (response.err_msg.toLowerCase().startsWith('otp max request')) {
                setErrOtp($t('auth.OpErrorNoAttemptOtp'));
            } else {
                setErrOtp(response.err_msg);
            }
        }
    };
    const fetchUserShopList = async (isCreateNewShop) => {
        const response = await getShopList();
        if (response?.shops) {
            let list = [];
            let listName = [];
            response.shops.forEach((shop) => {
                list.push({
                    id: shop.id,
                    name: shop.name,
                    isVerified: shop.is_verified,
                    currency: shop.currency_data || {
                        decimal_num: 0,
                        code: 'Rp',
                        decimal: ',',
                        thousand: '.'
                    }
                });
                listName.push(shop.name);
            });
            fetchUserRoles(list[0].id);
            localStorage.setItem(LS_SHOP, JSON.stringify(list));
            if (isCreateNewShop) {
                initiateShopInfo();
            }
            // dispatch(setListShop({ list }));
            // dispatch(setSelectedShop({ selected: list[0] }));
            // dispatch(setSelectedShopName({ selectedName: list[0].name }));
            // dispatch(setListShopName({ listName }));
        } else {
            // todo: handle error
        }
    };
    const fetchUserData = async () => {
        const response = await getUserData();
        if (response) {
            localStorage.setItem(LS_USERNAME, response.username ?? '');
            localStorage.setItem(LS_PHONE_NUMBER, response.phone_number);
        } else {
            // todo: handle error
        }
    };
    const fetchUserRoles = async (shopId) => {
        const response = await getUserRole();
        if (response) {
            const permission = response.filter((role) => role.shop_id === shopId)[0].dashboard_permissions;
            switch (response[0].dashboard_permissions[0]) {
                case 'dashboard.report.view':
                    navigate(`/sales`);
                    break;
                case 'dashboard.attendance.view':
                    navigate(`/attendance/history`);
                    break;
                case 'dashboard.menu.view':
                    navigate(`/products`);
                    break;
                case 'dashboard.crm.view':
                    navigate(`/buyers`);
                    break;
                case 'dashboard.inventory.view':
                    navigate(`/ingredients`);
                    break;
                case 'dashboard.table.view':
                    navigate(`/table-management/table-layout`);
                    break;
                default:
                    const permissions = response.filter((role) => role.shop_id === shopId)[0].dashboard_permissions;
                    const permission = permissions.filter((p) => p.split('.')[2] === 'view');
                    switch (permission[0]) {
                        case 'dashboard.report.view':
                            navigate(`/sales`);
                            break;
                        case 'dashboard.attendance.view':
                            navigate(`/attendance/history`);
                            break;
                        case 'dashboard.menu.view':
                            navigate(`/products`);
                            break;
                        case 'dashboard.crm.view':
                            navigate(`/buyers`);
                            break;
                        case 'dashboard.inventory.view':
                            navigate(`/ingredients`);
                            break;
                        case 'dashboard.table.view':
                            navigate(`/table-management/table-layout`);
                            break;
                    }
            }
            dispatch(setPermission(permission));
            localStorage.setItem(LS_ROLES, JSON.stringify(response));
        } else {
            // todo: handle error
        }
    };
    const logOut = () => {
        clearLS();
        dispatch(setInitialShopState());
        navigate(`/login`);
    };
    const handleNavigateRegister = () => {
        navigate('/register');
    };
    const clearLS = () => {
        localStorage.removeItem(LS_AUTH);
        localStorage.removeItem(LS_SHOP);
        localStorage.removeItem(LS_USERNAME);
        localStorage.removeItem(LS_PHONE_NUMBER);
        localStorage.removeItem(LS_USER);
        localStorage.removeItem(LS_SELECTED_SHOP_NAME);
    };
    useEffect(() => {
        if (location.pathname === '/login') {
            clearLS();
        }
    }, []);
    return {
        otp,
        setOtp,
        handleSendOtpToUser,
        handleValidateOtp,
        logOut,
        errOtp,
        fetchUserShopList,
        handleNavigateRegister,
        isRememberLogin,
        setIsRememberLogin
    };
};
